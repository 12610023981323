<div class="section-header-wrapper pb-3">
  <div class="flex justify-content-space-between align-items-center">
    <h5>Parties</h5>

    <div class="flex flex-1 justify-content-end gap-2">
      <span class="p-input-icon-left w-40">
        <i class="pi pi-search"></i>
        <input
          pInputText
          type="text"
          class="p-inputtext-xs w-100"
          (input)="searchInTable($event)"
          placeholder="Search customers by name, mobile number, etc."
        />
      </span>
      <p-button
        label="Add new party"
        styleClass="p-button-sm"
        icon="pi pi-plus-circle"
        iconPosition="left"
        (click)="createNewCustomer()"
      ></p-button>
    </div>
  </div>
</div>
<ng-container *ngIf="!isContentLoaded">
  <ngx-skeleton-loader
    count="8"
    appearance="line"
    animation="progress"
    [theme]="{
      'height': '3rem',
      'border-radius': 4
    }"
  >
  </ngx-skeleton-loader>
</ng-container>
<ng-container *ngIf="isContentLoaded">
  <p-tabView
    [(activeIndex)]="activeIndex"
    (onChange)="getPartiesOnTabChange($event.index)"
  >
    <ng-container *ngFor="let tab of tabToTypeMap">
      <p-tabPanel [header]="tab.tabLabel">
        <ng-container *ngIf="parties.length > 0">
          <p-table
            #customerTable
            [value]="parties"
            [paginator]="true"
            [rows]="10"
            [showCurrentPageReport]="true"
            styleClass="p-datatable-sm"
            [tableStyle]="{ 'min-width': '50rem' }"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [resizableColumns]="false"
            [globalFilterFields]="[
              'first_name',
              'last_name',
              'contact_details.mobile_number.value',
              'contact_details.email'
            ]"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>
                  <div
                    class="flex align-items-center justify-content-space-between"
                  >
                    Name
                  </div>
                </th>
                <th>
                  <div
                    class="flex align-items-center justify-content-space-between"
                  >
                    Mobile Number
                  </div>
                </th>
                <th>
                  <div
                    class="flex align-items-center justify-content-space-between"
                  >
                    Email
                  </div>
                </th>
                <th></th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-party>
              <tr (click)="viewCustomer(party)">
                <td>
                  <span class="p-column-title">Name</span>
                  {{ party.first_name }} {{ party.last_name }}
                </td>
                <td>
                  <span class="p-column-title">Mobile Number</span>
                  {{ party.contact_details?.mobile_number.value }}
                </td>
                <td>
                  <span class="p-column-title">Email</span>
                  {{ party.contact_details?.email }}
                </td>
                <td [style.width]="'5%'">
                  <p-button
                    icon="pi pi-ellipsis-v"
                    styleClass="p-button-sm p-button-secondary p-button-text"
                    (onClick)="openContextMenu($event, party)"
                  ></p-button>
                  <p-contextMenu
                    #contextMenu
                    appendTo="body"
                    [model]="contextMenuItems"
                  ></p-contextMenu>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td [attr.colspan]="3">
                  <div
                    class="flex flex-column align-items-center justify-content-center"
                  >
                    <img
                      class="no-data-image"
                      src="../../../assets/img/empty-folder.png"
                      alt="empty"
                    />
                    <span>No data found for your search criteria.</span>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </p-tabPanel>
    </ng-container>
  </p-tabView>
</ng-container>
