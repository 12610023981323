export const environment = {
  appVersion: require("../../../package.json").version + "-stg",
  defaultLanguage: "en",
  name: "STG",
  isProduction: false,
  isUat: false,
  isNotLocal: true,
  apiUrl: "https://bilvoic-dzt53gxypa-el.a.run.app", // "https://api-stg.astralbilling.com",
  firebase: {
    apiKey: "AIzaSyAsn-asPZsFRuvTDVQRYvirPjOs46N1Q1I",
    authDomain: "bilvoic-stg.firebaseapp.com",
    projectId: "bilvoic-stg",
    storageBucket: "bilvoic-stg.appspot.com",
    messagingSenderId: "79313194682",
    appId: "1:79313194682:web:66714cb5ec3e296e93fc83",
    measurementId: "G-GVVLKTDCDC",
  },
  recaptchaId: "6LdpjKwpAAAAAAoeitZTs7ZFOHdpJchlVXN6OXkp",
};
